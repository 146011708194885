import styled from 'styled-components';

export const DestinationViewWrapper = styled.div`
  .full-page {
    display: flex;
  }

  .banner img {
    object-fit: cover;
    width: 100%;
    height: 240px;
  }

  h1, h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 16px;
  }

  h2 {
    font-size: 18px;
    with: 100%;
    text-align: center;
    color : ${props => props.theme.color.primaryColor};
  }

  .sheet-sidebar {
    background: #fff;
    padding: 20px;
    font-family: ${props => props.theme.font.secondaryFont.name};
  }

  i {
    color: ${props => props.theme.color.primaryColor};
    font-size: 20px;
    margin-top: 2px;
    margin-right: 4px;
  }

  .sheet-block a, .sheet-details a {
    color: ${props => props.theme.color.primaryColor};
  }

  .sheet-article {
    margin-bottom: 32px;
    padding: 0;
  }
  
  .sheet-block h2 {
    color: ${props => props.theme.color.secondaryColor};
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-align: left;
    margin-bottom: 24px;
  }
  

  .sheet-block {
    font-size: 16px;
  }
  
  .sheet-list li {
    border-top: 1px solid #eeeeee;
  }
  
  .sheet-list li:first-child {
    border: none;
  }

  .sheet-details {
    background: rgba(150, 210, 0, 0.06);
    padding: 24px 0 10px 0;
  }
  
  .sheet-details i {
    background: #fff;
    border-radius: 50%;
    padding: 10px;
  }
  
  .sheet-details a:hover i {
    color: #fff;
  }
  
  .sheet-details a.active i {
    background: #05d39b;
    color: #fff;
  }
  
  .sheet-details button:hover i {
    background: #05d39b;
    color: #fff;
  }
  
  .sheet-details button.active i {
    background: #05d39b;
    color: #fff;
  }
  
  .sheet-details span {
    color: #4a4a4a;
    display: block;
    font-size: 12px;
    margin: 0 auto 0.5em auto;
    margin-top: 12px;
  }
  
  
  .sheet-details .icon {
    font-size: 36px;
    margin: 10px;
  }

  .btn {
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    background-color: ${props => props.theme.color.primaryColor};
    color: white;
  }
  
  .button-block {
    display: flex;
    justify-content: center;
  }

  .button-block a {
    width: 80%;
  }

  @media only screen and (min-width: 1024px) {
    .btn {
      min-width: 350px;
    }
  }
  
  @media (min-width: 768px) {
    .sheet-sidebar {
      margin-right: 10px;
      margin-top: -50px;
    }
    .banner img {
      height: 320px;
    }
    h1 {
      margin: 12px 0 8px 0; 
    }
    .button-block a {
      width: auto;
    }
    .btn {
      margin: 16px 0;
    }
  }
  


  @media (min-width: 1200px) {
    .container {
      max-width: none;
    }
    .container-title {
      padding-right: 9rem;
      padding-left: 9rem;
    }
    .slider-experts-container {
      margin: 0;
    }
  }
  @media (min-width: 850px) {
    .contact {
      position: sticky;
      top: ${props => props.contactTopPosition};
      margin-left: calc(100% - 300px);
      margin-top: -430px;
      height: 430px;
      width: 300px;
    }
  }
`;
