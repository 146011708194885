import React, { useEffect, useState, useContext } from 'react';
import { LanguageContext, WhiteLabelContext, ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
// import PropTypes from 'prop-types';
import formatHour from 'ToolboxUtils/web/formats/format-hour';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';

const HomeTimeWrapper = styled.div`
  font-size: ${props => `${18*props.theme.font.primaryFont.scaling/100}px`};
  font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  padding: 10px;

  @media (min-width: ${isMobileBreakpoint}px) {
    display: flex;
    font-size: ${props => `${24*props.theme.font.primaryFont.scaling/100}px`};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};

    div + div {
      margin-left: 6px;
    }
  }
`;

const HomeTime = props => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const [city] = useContext(WhiteLabelContext).usePath('city.name');
  const [time, setTime] = useState(formatHour({ date: new Date(), language }));
  const [data, setData] = useState({ time: '00:00', city: '' });

  useEffect(() => {
    setInterval(
      () => setTime(formatHour({ date: new Date(), language })),
      1000,
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData({ time, city });
    // eslint-disable-next-line
  }, [time, city]);
  return (
    <HomeTimeWrapper className={`home-time`} theme={theme}>
      {data.time !== undefined && (
        <Text path={'page.homepage.timeLocation'} data={data} />
      )}
    </HomeTimeWrapper>
  );
};

HomeTime.propTypes = {};

export default HomeTime;
