import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, WhiteLabelContext, LanguageContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import Text from 'ToolboxComponents/commons/texts/text/text';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Map from 'ToolboxComponents/commons/map/map';
import DestinationSlider from 'ToolboxComponents/webapp/pages/destination-view/components/destination-slider/destination-slider';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';


import { DestinationViewWrapper } from './destination-view.styles';

const DestinationView = ({ products, concierge, addFavorite, deleteFavorite }) => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();

  const [isMobile, setIsMobile] = useState(isMobileView());

  return (
    <DestinationViewWrapper
      theme={theme}
      isMobile={isMobile}
    >
      <div class="banner">
        <ImageResize
            width={`large`}
            src={whiteLabel.pictures.destination}
        />
      </div>
      <div className="full-page">
        <div className="col-lg-7 col-xl-8 sheet-article">
          <div className="d-md-none pb-4">
            <div className="sheet-details">
              <div className="d-flex flex-wrap justify-content-around text-center">
                <div>
                  <a href={"tel:" + concierge.phone}>
                    <i className='icon adn adn-phone' />
                    <span><Text path='page.destination.call' /></span>
                  </a>
                </div>
                <div>
                  <a href={whiteLabel.flagshipUrl[language] || whiteLabel.flagshipUrl['fr'] } target="_blank">
                  <i className='icon adn adn-web' />
                    <span><Text path='page.destination.website' /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
            <h1><Text path='page.destination.title' /></h1>
            <p><Text path='page.destination.description' /></p>
            <h2><Text path='page.destination.sliderTitle' /></h2>
            <DestinationSlider
              key={1}
              products={products}
              addFavorite={event => addFavorite(event)}
              deleteFavorite={event => deleteFavorite(event)}
            />
            <div className="button-block">
              <Anchor code="collection" className="btn btn-default d-block d-sm-inline-block">
                <Text path='page.destination.button' />
              </Anchor>
            </div>
        </div>
        <div className="col-lg-5 col-xl-4 d-none d-lg-block">
          <div className="sheet-sidebar position-sticky" style={{top: 70}}>
            <div className="sheet-block sheet-contact">
              <div className="container">
                <h2><Text path='page.destination.conciergeTitle' /></h2>
                <ul className="list-unstyled sheet-list m-0">
                  <li className="pb-4">
                    <a href={"tel:" + concierge.phone}><i className='icon adn adn-phone' />&nbsp;&nbsp;{concierge.phone}</a>
                    </li>
                    <li className="pb-4 pt-4">
                      <a href={"mailto:" + concierge.mail}><i className='icon adn adn-email' />&nbsp;&nbsp;{concierge.mail}</a>
                      </li>
                  {/*
                    <li className="pb-4 pt-4">
                    <i className="material-icons mr-4">mail</i>Facebook Messenger
                    </li>
                  */}
                  <li className="pb-4 pt-4 d-flex address">
                    <i className='icon adn adn-map-marker' />&nbsp;&nbsp;
                    <span>
                      {concierge.address1 + '\n'}<br />
                      {concierge.address2 ? concierge.address2 + '\n' : null}
                      {concierge.postalCode} &nbsp;
                      {concierge.city}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sheet-block mb-4">
              <Map
                className={`map`}
                height={`200px`}
                itemLocations={[concierge]}
                selected={0}
                zoom={16}
              />
            </div>
          </div>
        </div>
      </div>

    </DestinationViewWrapper>
  );
}

DestinationView.propTypes = {
  concierge: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
}

export default DestinationView;
