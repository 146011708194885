import React from 'react';
import PropTypes from 'prop-types';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import ProductCard from 'ToolboxComponents/commons/cards/product-card/product-card';
import HomeTime from 'ToolboxComponents/webapp/pages/home-view/components/home-time/home-time';


const DestinationSliderWrapper = styled.div`
  .swiper-slide {
    padding: 0 15px;
  }

  .swiper-container {
    padding: 0;
  }

  .swiper-pagination-design {
    top: 0;
  }
  @media (min-width: ${isMobileBreakpoint}px) {
    .swiper-slide {
      padding: 0;
    }
  }
`;

const DestinationSlider = props => {
  const { products, addFavorite, deleteFavorite } = props;

  const toggleFavoriteActive = (active, productId) => {
    if (active === true) {
      deleteFavorite(productId);
    } else {
      addFavorite(productId);
    }
  };

  return (
    <>
    <DestinationSliderWrapper className={`home-slider-product`}>
      <Slider
        mobile={{
          dots: true,
        }}
        desktop={{
          slidesPerView: 2,
          arrows: true,
          spaceBetween: 40,
          slidesPadding: 20,
          centerInsufficientSlides: true,
        }}
      >
        {products !== undefined &&
          products.map((product, index) => (
            <ProductCard
              key={`slide-product--${index}`}
              id={`slide-product--${index}`}
              product={product}
              toggleFavoriteActive={toggleFavoriteActive}
            />
          ))}
      </Slider>
    </DestinationSliderWrapper>
    </>
  );
};

DestinationSlider.propTypes = {
  products: PropTypes.array,
};

export default DestinationSlider;
